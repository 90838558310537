<div mat-dialog-title data-cy="change-mp-popup">
  <div class="dialog-title">
    <h3>{{ 'header.select-measurement.title' | translate }}</h3>
    <button mat-icon-button data-cy="close" (click)="onClose()">
      <mat-icon svgIcon="close"></mat-icon>
    </button>
  </div>
  <div class="filters">
    <app-filter
      [searchFilter]="searchFilter"
      (searchFilterChange)="searchFilterChange($event)"
      [from]="'change'"
      [search]="true"
      [stateFilter]="stateFilter"
      (stateFilterChange)="stateFilterChange($event)"
      [(stateOptions)]="stateOptions"
      [clearButton]="true"
      (clearFilters)="clearFilters($event)"
      [state]="true"
      [userPreferenceName]="filtersPreferenceName"
    ></app-filter>
  </div>
</div>
<mat-dialog-content class="mat-typography">
  @if (!loading) {
  <div class="location-group" data-cy="search-partner">
    @if (emptyList) {
    <div>{{ 'global.noResults' | translate }}</div>
    } @for (partner of partners; track partner; let i = $index) { @if (filteredFleetList?.[i] !== false) {
    <div class="partner-group">
      @if (partner.accountName) {
      <h2>{{ partner.accountName }}</h2>
      }
      <mat-accordion data-cy="customer-group">
        @for (customer of partner.customers; track customer; let i2 = $index) {
        <div [@.disabled]="disableAnimation" class="customer-group">
          @if (filteredFleetList?.[i]?.[i2] !== false) {
          <mat-expansion-panel
            #accountPanel
            data-cy="expand-collapse-button"
            [expanded]="customer.expanded || customer.accountId === currentAccount"
          >
            <mat-expansion-panel-header>
              <mat-panel-title data-cy="search-customer">
                {{ customer.accountName }}
                @if (customer.measurementPoints.length > 1 && !filteredFleetList?.[i]?.[i2]?.count) { ({{
                  customer.measurementPoints.length
                }}) } @if (filteredFleetList?.[i]?.[i2]?.count > 1) { ({{ filteredFleetList?.[i]?.[i2]?.count }}) } @if
                (customer.subscriptionType === 'PREMIUM') {
                <mat-icon svgIcon="decagram" class="premium-icon"></mat-icon>
                }
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="location table">
              <table data-cy="change-selection-table">
                <thead>
                  <tr>
                    <th>{{ 'fleet.table.site' | translate }}</th>
                    <th>
                      {{ 'fleet.table.measurement-point' | translate }}
                    </th>
                    <th>{{ 'fleet.table.state' | translate }}</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  @for (point of customer.measurementPoints; track point; let i3 = $index) { @if
                  (filteredFleetList?.[i]?.[i2]?.[i3] !== false) {
                  <tr
                    class="mp"
                    [ngClass]="'row-' + point.measurementPointId"
                    (click)="selectPoint(point, customer)"
                    data-cy="select-mp"
                  >
                    <td data-cy="search-location">
                      <span [matTooltip]="point.city + ', ' + point.state">{{ point.locationName }}</span>
                    </td>
                    <td data-cy="search-mp">
                      @if (point.measurementPointTypeId === 1 && point.pqubeModel !== 'PQ Edge') {
                      <span>
                        <img class="drivescanIcon" src="./assets/images/icon-qubescan-pqube-3.svg" />
                      </span>
                      } @if (point.measurementPointTypeId === 1 && point.pqubeModel === 'PQ Edge') {
                      <span>
                        <img class="drivescanIcon" src="./assets/images/icon-qubescan-pqube-edge.svg" />
                      </span>
                      } @if (point.measurementPointTypeId === 2) {
                      <span>
                        <mat-icon svgIcon="psl" color="accent"></mat-icon>
                      </span>
                      } @if (point.measurementPointTypeId === 3) {
                      <span>
                        <img class="drivescanIcon" src="./assets/images/DriveScan-logo_icon.png" />
                      </span>
                      }
                      {{ point.mpId }}
                    </td>
                    <td>
                      {{ 'measurementPoint.status.' + point.measurementPointStatusId | translate }}
                    </td>
                    <td>
                      <button
                        id="button-{{ point.measurementPointId }}"
                        class="change-selection-button"
                        mat-icon-button
                      >
                        <mat-icon [svgIcon]="'arrow-right'" color="accent"></mat-icon>
                      </button>
                    </td>
                  </tr>
                  } }
                </tbody>
              </table>
            </div>
          </mat-expansion-panel>
          }
        </div>
        }
      </mat-accordion>
    </div>
    } }
  </div>
  } @if (loading) {
  <div class="loading">
    <mat-spinner diameter="48" color="primary"></mat-spinner>
  </div>
  }
</mat-dialog-content>
